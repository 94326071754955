<!--ReleaseLegend-->
<template>
  <div class="flex items-center gap-16">
    <div class="flex items-center gap-4">
      <i class="w-12 h-12 rounded-full shrink-0 bg-[#B8CEFF]"></i>
      <span class="text-md font-medium leading-lg text-on-surface-elevation-2">{{
        $t('studio.prj_prod.status_review_requested')
      }}</span>
    </div>
    <div class="flex items-center gap-4">
      <i class="w-12 h-12 rounded-full shrink-0 bg-[#FFC226]"></i>
      <span class="text-md font-medium leading-lg text-on-surface-elevation-2">{{
        $t('studio.prj_prod.status_in_review')
      }}</span>
    </div>
    <div class="flex items-center gap-4">
      <i class="w-12 h-12 rounded-full shrink-0 bg-success"></i>
      <span class="text-md font-medium leading-lg text-on-surface-elevation-2">{{
        $t('studio.prj_prod.status_review_completed')
      }}</span>
    </div>
    <div class="flex items-center gap-4">
      <i class="w-12 h-12 rounded-full shrink-0 bg-tint-red-a400"></i>
      <span class="text-md font-medium leading-lg text-on-surface-elevation-2">{{
        $t('studio.prj_prod.status_review_declined')
      }}</span>
    </div>
    <div class="flex items-center gap-4">
      <i class="w-12 h-12 rounded-full shrink-0 bg-brand-primary"></i>
      <span class="text-md font-medium leading-lg text-on-surface-elevation-2">{{
        $t('studio.prj_prod.status_live')
      }}</span>
    </div>
    <div class="flex items-center gap-4">
      <i class="w-12 h-12 rounded-full shrink-0 bg-disabled-variant-1"></i>
      <span class="text-md font-medium leading-lg text-on-surface-elevation-2">{{
        $t('studio.prj_prod.status_suspend_or_deleted')
      }}</span>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
