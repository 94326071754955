<!--ReleaseTitleComponent-->
<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center gap-16 min-h-32">
      <div class="flex gap-4 items-center">
        <span
          class="flex items-center h-24 px-[.6rem] bg-blue50 text-xs font-bold leading-xs text-brand-primary rounded-lg"
        >
          {{ productDetailLabel }}
        </span>
        <span class="text-md font-medium leading-sm text-on-surface-elevation-1">
          {{ productName }}</span>
      </div>
      <div v-if="isLive" class="flex items-center gap-8 ml-auto">
        <s-button
          variant="outline"
          size="sm"
          icon="ic-v2-navigation-store-line"
          :isDisabled="product?.displayYn === Confirmation.NO"
          @click="goToStore"
        >
          {{ $t('studio.prj_prod.this_prod.release_stt_calendarview_store_dir_btn') }}
        </s-button>
        <s-button
          variant="outline"
          size="sm"
          icon="ic-v2-navigation-community-line"
          :isDisabled="product?.displayYn === Confirmation.NO || !props.communityId"
          @click="goToCommunity"
        >
          {{ $t('studio.prj_prod.this_prod.release_stt_calendarview_community_dir_btn') }}
        </s-button>
      </div>
    </div>
    <h2 class="text-5xl font-bold leading-4xl text-on-surface-elevation-1">
      {{ $t('studio.prj_prod.this_prod.release_stt_title') }}
    </h2>
    <p class="text-md leading-lg text-on-surface-elevation-2">
      {{ $t('studio.prj_prod.this_prod.release_stt_msg') }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { PLAN_STATUS, VERIFY_STATUS } from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import { getConfigs, redirectTo } from '@/utils/common.util';

const props = defineProps<{
  communityId?: number;
}>();

const productStore = useProductStore();
const { product, productDetailLabel, productName } = storeToRefs(productStore);
const params = useRoute().params;
const productNo: number = parseInt(params.productId as string, 10);

const { locale } = useI18n();
const storeHref = computed(() => {
  const { STORE_URL } = getConfigs();
  return `${STORE_URL}/${locale.value}`;
});

const isLive = computed(() => {
  const isCorrectPlanStatus =
    product.value?.planStatus === PLAN_STATUS.PAGE_OPEN ||
    product.value?.planStatus === PLAN_STATUS.RELEASE ||
    product.value?.planStatus === PLAN_STATUS.PRE_PURCHASE;
  return product.value?.verifyStatus === VERIFY_STATUS.PASS && isCorrectPlanStatus;
});

const goToStore = async () => {
  const url = `${storeHref.value}/games/${productNo}`;
  await redirectTo(url, { external: true, open: { target: '_blank' } });
};

const goToCommunity = () => {
  const url = `${storeHref.value}/games/${productNo}/community/${props.communityId}`;
  redirectTo(url, { external: true, open: { target: '_blank' } });
};
</script>
