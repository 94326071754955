<template>
  <!-- Banner -->
  <div
    v-if="isShow"
    class="flex items-center gap-8 mt-40 px-[3rem] py-24 bg-white rounded-[2.8rem] shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] [&+.mt-40]:!mt-24"
  >
    <div class="flex-1 flex items-center gap-8 text-on-surface-elevation-2">
      <img src="@/assets/images/release-plan/icon-calendar.svg" alt="" class="w-full max-w-80" />
      <s-text as="p" role="text2" class="text-on-surface-elevation-2 font-medium">
        <safe-html :html="$t('studio.release_list.no_record_msg1')" />
        <br />
        <safe-html :html="$t('studio.release_list.no_record_msg2')" />
      </s-text>
    </div>
    <button type="button" class="shrink-0 ml-8" @click="closeBanner()">
      <s-icon
        size="3xl"
        icon="ic-v2-control-close-line"
        srOnlyText="Close"
        class="text-on-surface-elevation-3"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import SafeHtml from '@/components/common/safe-html.vue';

const isShow = ref<boolean>(true);
const params = useRoute().params;
const productNo: number = parseInt(params.productId as string, 10);
const isFirstReleaseList: number[] =
  JSON.parse(localStorage.getItem('releaseBannerFirstTime') ?? '[]') ?? [];

const props = defineProps<{
  isShowBanner: boolean;
}>();

onMounted(() => {
  isShow.value = props.isShowBanner;
  showReleaseBannerFirstTime();
});

const showReleaseBannerFirstTime = () => {
  const isExitsRelease: boolean = !!isFirstReleaseList?.find((item: number) => item === productNo);
  if (isExitsRelease) {
    isShow.value = false;
  }
};

const closeBanner = () => {
  localStorage.setItem(
    'releaseBannerFirstTime',
    JSON.stringify([...isFirstReleaseList, productNo])
  );
  isShow.value = false;
};
</script>

<style scoped lang="scss"></style>
