import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

export const CALENDAR_VIEWS = {
  YEAR: 'dayGridYear',
  DAY: 'timeGridDay',
  WEEK: 'timeGridWeek',
  MONTH: 'dayGridMonth'
};
export const defaultHeaderToolbar = {
  left: 'today prev,next title',
  center: '',
  right: 'custom'
  // right: `${CALENDAR_VIEWS.YEAR},${CALENDAR_VIEWS.MONTH},${CALENDAR_VIEWS.WEEK},${CALENDAR_VIEWS.DAY}`
};

export const defaultInitialView = `${CALENDAR_VIEWS.MONTH}`;

export const defaultPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
