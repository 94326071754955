<template>
  <s-dialog to="studio-release-plan" open>
    <s-dialog-panel class="!w-full !h-full !max-h-[initial] !rounded-none">
      <div class="flex h-full w-full flex-col">
        <release-dialog-header :releaseDialogTitle="$t('studio.prj_prod.this_prod.release_stt_title')">
          <!--TODO: Show when product starting-->
          <s-button
            v-if="!storeReleasePlanInformation ||
              !storeReleasePlanInformation.planStatus ||
              (storeReleasePlanInformation.planStatus === PLAN_STATUS.NONE && product?.verifyStatus === VERIFY_STATUS.NONE && !calendarData?.length)"
            variant="primary"
            size="md"
            class="mb-8"
            @click="goToDetail()"
          >
            {{ $t('studio.prj_prod.this_prod.release_stt_browser_release_btn') }}
          </s-button>
          <!--TODO: Show when product is reviewing & released-->
          <s-button v-else variant="primary" size="md" class="mb-8" @click="goToDetail()">
            {{ $t('studio.prj_prod.this_prod.release_stt_browser_product_release_info_btn') }}
          </s-button>
        </release-dialog-header>
        <div v-end-scroll="loadMore" class="release-setting studio-scrollbar-4 !overflow-y-auto flex-1 pt-36 pb-[12rem] bg-[rgba(11,94,255,0.05)] pl-20">
          <div class="max-w-[120rem] mx-auto mb-32">
            <release-title
              :communityId="productMeta?.baseBoardSeq"
            />
            <release-banner-first-time :isShowBanner="isShowBannerFirstTime" />
            <st-box class="mt-40 !py-[3rem] !rounded-[4rem] min-h-[34.8rem]">
              <div class="flex items-center gap-24 h-44">
                <div class="flex items-center gap-16">
                  <s-button
                    variant="outline"
                    size="sm"
                    :isDisabled="isCurrentMonth"
                    @click="onCurrentMonth"
                  >
                    {{ $t('studio.prj_prod.this_prod.release_stt_calendar_today_btn') }}
                  </s-button>
                  <button type="button" @click="onPrev">
                    <s-icon size="4xl" icon="ic-v2-control-arrow-left-line" :srOnlyText="$t('studio.prj_prod_mngmt.rating_build_review.self_review.qtn_back_btn')" />
                  </button>
                  <button type="button" @click="onNext">
                    <s-icon size="4xl" icon="ic-v2-control-arrow-right-line" :srOnlyText="$t('studio.prj_prod_mngmt.rating_build_review.self_review.qtn_next_btn')" />
                  </button>
                  <datepicker
                    id="calendar-date-picker"
                    v-model="selectedDate"
                    inputClass="!min-w-0 !px-0 !text-4xl !font-bold !leading-3xl"
                    wrapperClass="!w-100 !bg-transparent !outline-0 before:!hidden tracking-[0]"
                    size="sm"
                    minimumView="month"
                    :language="locale === LanguageCode.Ko ? LanguageCode.Ko : LanguageCode.En"
                    :format="MONTH_FORMAT_WITH_DOT"
                  />
                </div>
                <div class="ml-auto flex items-center gap-16">
                  <button type="button" @click="onSelectedViewModeCalendar">
                    <s-icon
                      size="4xl"
                      :icon="isViewModeCalendar? 'ic-v2-community-calendar-fill': 'ic-v2-community-calendar-line'"
                      :class="isViewModeCalendar ? 'text-brand-primary' : 'text-on-surface-elevation-4'"
                      srOnlyText="캘린더형 보기"
                    />
                  </button>
                  <button type="button" @click="onSelectedViewModeList">
                    <s-icon
                      size="4xl"
                      icon="ic-v2-community-view-list-fill"
                      :class="!isViewModeCalendar ? 'text-brand-primary' : 'text-on-surface-elevation-4'"
                      srOnlyText="리스트형 보기"
                    />
                  </button>
                </div>
              </div>
              <div class="flex items-center gap-24 mt-24">
                <release-legend />
                <div class="ml-auto shrink-0">
                  <release-filter :modelValue="filterValue" @update:modelValue="(value: string[]) => filterValue = value" />
                </div>
              </div>
              <div class="mt-16">
                <full-calendar
                  v-if="isViewModeCalendar && showCalendar"
                  :id="uuid"
                  ref="calendarElm"
                  class="studio-calendar"
                  :options="{
                    ...calendarOptions,
                    weekends,
                    timeZone: selectedTimezone,
                    locale,
                    events: handleEvents,
                    loading: handleLoading
                  }"
                >
                  <template #eventContent="arg">
                    <p
                      class="flex items-center gap-4 border-0 px-[.6rem] py-0 rounded-md w-full text-left"
                      :style="`background-color: ${colorMapping(arg.event.extendedProps.type)?.bg}1F; color: ${colorMapping(arg.event.extendedProps.type)?.color}`"
                    >
                      <i class="w-8 h-8 cursor-pointer rounded-full shrink-0" :style="`background-color: ${colorMapping(arg.event.extendedProps.type)?.bg}`"></i>
                      <pre class="w-[calc(100%-1rem)] truncate text-xs font-medium leading-sm font-[inherit]">{{ arg.event.title }}</pre>
                    </p>
                  </template>
                  <!--template #eventContent="arg">
                    <template v-if="getContentToolTip(arg.event.extendedProps.isProductPageOpen, arg.event.extendedProps.rawData)">
                      <s-tooltip
                        arrow
                        :allowHTML="true"
                        :content="getContentToolTip(arg.event.extendedProps.isProductPageOpen, arg.event.extendedProps.rawData)"
                        :duration="0"
                        :distance="4"
                        useFlip
                        flipOnUpdate
                        placement="bottom-start"
                        trigger="mouseenter focus"
                        :theme="'studio-tooltip'"
                        :zIndex="UPPER_DIALOG_Z_INDEX"
                        class="flex items-center gap-4 border-0 px-[.6rem] py-0 rounded-md w-full text-left"
                        :style="`background-color: ${
                          colorMapping(arg.event.extendedProps.type)?.bg
                        }1F; color: ${colorMapping(arg.event.extendedProps.type)?.color}`"
                      >
                        <template #target>
                          <i class="w-8 h-8 cursor-pointer rounded-full shrink-0" :style="`background-color: ${colorMapping(arg.event.extendedProps.type)?.bg}`"></i>
                          <pre class="w-[calc(100%-1rem)] truncate text-xs font-medium leading-sm font-[inherit]">{{ arg.event.title }}</pre>
                        </template>
                      </s-tooltip>
                    </template>
                    <template v-else>
                      <p
                        class="flex items-center gap-4 border-0 px-[.6rem] py-0 rounded-md w-full text-left"
                        :style="`background-color: ${colorMapping(arg.event.extendedProps.type)?.bg}1F; color: ${colorMapping(arg.event.extendedProps.type)?.color}`"
                      >
                        <i class="w-8 h-8 cursor-pointer rounded-full shrink-0" :style="`background-color: ${colorMapping(arg.event.extendedProps.type)?.bg}`"></i>
                        <pre class="w-[calc(100%-1rem)] truncate text-xs font-medium leading-sm font-[inherit]">{{ arg.event.title }}</pre>
                      </p>
                    </template>
                  </template-->
                </full-calendar>
              </div>
              <div v-if="!isViewModeCalendar && releaseHistoryListGrouped?.length" class="mt-24">
                <template v-if="releaseHistoryListGrouped?.length">
                  <div class="flex flex-col gap-24">
                    <div v-for="releaseHistoryList in releaseHistoryListGrouped" :key="releaseHistoryList.date" class="flex flex-col gap-16">
                      <p class="text-4xl font-bold leading-3xl text-on-surface-elevation-1">
                        <!--                        13일 수요일-->
                        {{ getOrdinalSuffixByLocale(releaseHistoryList.date) }} {{ getTheDayOfTheWeekByLocale(releaseHistoryList.date) }}
                      </p>
                      <div class="flex flex-col gap-12">
                        <list-release-state
                          v-for="releaseHistory in releaseHistoryList.items"
                          :key="releaseHistory.type"
                          :dateTime="getDateTimeByLocale(releaseHistory?.period.startedAt)"
                          :stateMessage="$t(PRODUCT_STATUS_MAP_LANGUAGE_KEY[releaseHistory.type])"
                          :type="PRODUCT_STATUS_MAP[releaseHistory.type] || ''"
                        >
                          <p class="text-md leading-lg font-medium text-on-surface-elevation-2">
                            <span v-if="releaseHistory.page?.pageName">{{ releaseHistory.page?.pageName }} / </span>{{ $t('studio.home.release_timeline.prod_pg_item.community_open') }}
                            <span v-if="releaseHistory?.plans?.pageOpen?.earlyAccess"> / {{ $t('studio.home.release_timeline.prod_pg_item.community_early_access') }}</span>
                            <span v-if="releaseHistory?.plans?.pageOpen?.earlyAccess">
                              ({{ getDateTimeByLocale(releaseHistory?.plans?.pageOpen?.earlyAccess?.startedAt) }}
                              ~
                              {{ getDateTimeByLocale(releaseHistory?.plans?.pageOpen?.earlyAccess?.endedAt) }})
                            </span>
                          </p>
                          <p v-if="releaseHistory?.build || releaseHistory?.ratings || releaseHistory.extraFeatures?.length" class="text-md leading-lg font-medium text-on-surface-elevation-2">
                            <span v-if="releaseHistory?.build">
                              {{ releaseHistory?.build?.buildNo }}, {{ releaseHistory?.build?.buildName }}
                            </span>
                            <span v-if="releaseHistory?.ratings">
                              ({{ releaseHistory?.ratings?.map((item: any) => t(getAgeRatingText(item.ageRating), { rating: item.ageRating })).join(', ') }})
                            </span>
                            <span v-if="(releaseHistory?.ratings?.length || releaseHistory?.build) && releaseHistory.extraFeatures?.length"> / </span>
                            <span v-if="releaseHistory.extraFeatures?.length">
                              {{ releaseHistory?.extraFeatures?.filter((item: string) => item !== EXTRA_FEATURES.PC_PURCHASE_CONDITION).map((item: string) => t(EXTRA_FEATURES_KEY[item])).join(', ') }}
                            </span>
                          </p>
                          <p v-if="releaseHistory.sales || releaseHistory.plans?.release?.saleLimit || releaseHistory.plans?.prePurchase" class="text-md leading-lg font-medium text-on-surface-elevation-2">
                            <span v-if="releaseHistory.sales">
                              <template v-if="!releaseHistory.sales?.price">
                                {{ $t('studio.prj_prod.this_prod.release_details_sales_setting_price_setting_sales_type_this_type_free') }}
                              </template>
                              <template v-else>
                                {{ formatPrice(releaseHistory.sales?.price, CurrencyCode.Usd) }}USD
                              </template>
                            </span>
                            <span v-if="releaseHistory.plans?.release?.saleLimit">
                              <span v-if="releaseHistory.sales">, </span>
                              <span v-if="releaseHistory.plans?.release?.saleLimit.limitCount">
                                {{ $t('studio.home.release_timeline.prod_pg_item.price_sale_limit') }}
                                ({{ t('studio.home.release_timeline.prod_ptyitem.price_sale_limit_quantity', { limitedNum: releaseHistory.plans?.release?.saleLimit.limitCount }) }},{{ $t('studio.home.release_timeline.prod_pg_item.price_sale_limit_guide') }})
                              </span>
                              <span v-if="releaseHistory.plans?.release?.saleLimit?.limitEndedAt">
                                {{ $t('studio.home.release_timeline.prod_pg_item.price_sale_period_terminate_guide').toLowerCase() }} ({{ getDateTimeByLocale(releaseHistory.plans?.release?.saleLimit?.limitEndedAt) }})
                              </span>
                            </span>
                            <span v-if="releaseHistory.extraFeatures?.length && releaseHistory.extraFeatures?.find(item => item === EXTRA_FEATURES.PC_PURCHASE_CONDITION)">
                              <span v-if="releaseHistory.plans?.release?.saleLimit || releaseHistory.sales"> / </span>
                              {{ $t('studio.home.release_timeline.prod_pg_item.price_purchase_cond_set') }}
                            </span>
                          </p>
                          <p v-if="releaseHistory.plans?.pageOpen?.startedAt || releaseHistory.plans?.prePurchase || releaseHistory.plans?.release?.startedAt" class="text-md leading-lg font-medium text-on-surface-elevation-2">
                            <span v-if="releaseHistory.plans?.pageOpen?.startedAt">
                              {{ $t('studio.prj_prod.this_prod.release_details_release_plan_release_timeline_prod_pg_open') }} ({{ getDateTimeByLocale(releaseHistory.plans?.pageOpen?.startedAt) }})
                            </span>
                            <span v-if="releaseHistory.plans?.pageOpen?.startedAt && releaseHistory.plans?.prePurchase">, </span>
                            <span v-if="releaseHistory.plans?.prePurchase">
                              {{ $t('studio.home.release_timeline.prod_pg_item.release_plan_pre_order') }}
                              ({{ getDateTimeByLocale(releaseHistory.plans?.prePurchase.startedAt) }} ~ {{ getDateTimeByLocale(releaseHistory.plans?.prePurchase.endedAt) }}
                              <template v-if="releaseHistory.plans?.prePurchase?.downloadInstallYn">
                                <template v-if="releaseHistory.plans?.prePurchase?.downloadInstallYn === Confirmation.NO">
                                  , {{ $t('studio.home.release_timeline.prod_pg_item.release_plan_download_install_allow_n') }}
                                </template>
                                <template v-else>
                                  , {{ $t('studio.home.release_timeline.prod_pg_item.release_plan_download_install_allow_yn') }}
                                </template>
                              </template>
                              <template v-if="releaseHistory.plans?.prePurchase?.playOption">
                                <template v-if="releaseHistory.plans?.prePurchase?.playOption === PLAY_OPTION.NONE">
                                  , {{ $t('studio.home.release_timeline.prod_pg_item.release_plan_exe_allow_n') }}
                                </template>
                                <template v-else>
                                  , {{ $t('studio.home.release_timeline.prod_pg_item.release_plan_exe_allow_yn') }}
                                </template>
                              </template>)
                            </span>
                            <span v-if="releaseHistory.plans?.prePurchase && releaseHistory.plans?.release?.startedAt">, </span>
                            <span v-if="releaseHistory.plans?.release?.startedAt">
                              {{ $t('studio.home.release_timeline.prod_pg_item.release_plan_official_release_date') }}
                              ({{ getDateTimeByLocale(releaseHistory.plans?.release?.startedAt) }})
                            </span>
                          </p>
                        </list-release-state>
                      </div>
                    </div>
                  </div>
                </template>
                <p v-else class="flex justify-center items-center min-h-[35rem] text-md leading-lg text-on-surface-elevation-2 text-center">
                  {{ $t('studio.prj_prod.this_prod.release_stt_event_list_ex_msg') }}
                </p>
              </div>
              <div v-else-if="!isViewModeCalendar && !releaseHistoryListGrouped?.length" class="mt-24">
                <div class="flex justify-center items-center min-h-[27.4rem]">
                  <s-text as="p" role="text3" class="text-on-surface-elevation-2">{{ $t('studio.prj_prod.this_prod.release_stt_calendar_event_msg') }}</s-text>
                </div>
              </div>
            </st-box>
            <st-box v-if="isViewModeCalendar && releaseHistoryListGrouped?.length" class="mt-40 !p-40">
              <div v-if="releaseHistoryListGrouped?.length" class="flex flex-col gap-20 overflow-hidden">
                <release-step v-for="(calendarGroup, iGroup) in releaseHistoryListGrouped" :key="calendarGroup.date" :dayValue="getOrdinalSuffixByLocale(calendarGroup.date)">
                  <template v-for="(calendar, iItem) in calendarGroup.items" :key="calendar.start">
                    <release-step-item
                      :type="calendar.calendarType as string || ''"
                      :dateTime="getDateTimeCalendarList(calendar)"
                      :stateMessage="$t(PRODUCT_STATUS_MAP_LANGUAGE_KEY[calendar.type as string])"
                      :releaseTime="releaseTime"
                      :isFirst="iGroup === 0 && iItem === 0"
                      :isPastTimeLine="formatToDateTime(calendar.period?.startedAt).startOf('day').toMillis() >= formatToDateTime(new Date()).startOf('day').toMillis()"
                      :inMultiItems="calendarGroup.items.length > 1 || releaseHistoryListGrouped.length > 1"
                    />
                  </template>
                </release-step>
              </div>
              <div v-else class="flex flex-col gap-20 overflow-hidden">
                <release-step :dayValue="getOrdinalSuffixByLocale(new Date().toISOString())" isBeforeRelease>
                  <release-step-item
                    :type="CALENDAR_RELEASE_PLAN_STATUS.STOP"
                    :stateMessage="$t('studio.prj_prod.this_prod.release_stt_calendar_event_msg')"
                  />
                </release-step>
              </div>
            </st-box>
          </div>
        </div>
      </div>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="studio-release-plan" />
</template>
<script setup lang="ts">
import type {
  CalendarOptions,
  DayCellContentArg,
  EventInput,
  EventSourceFuncArg
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue3';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta, formatToDateTime, showDialog } from '@/.nuxt/imports';
import { fetchProductsMetaApi } from '@/apis/products.api';
import {
  fetchProductReleaseHistoryListApi,
  fetchProductReleaseHistoryListSimpleApi,
  fetchReleasePlanImageReleasedApi
} from '@/apis/release-plan.api';
import StBox from '@/components/common/st-box.vue';
import ReleaseCompletionDialog from '@/components/release-plan/dialog/release-completion-dialog.vue';
import ListReleaseState from '@/components/release-plan/list-release-state.vue';
import ReleaseBannerFirstTime from '@/components/release-plan/release-banner-first-time.vue';
import ReleaseDialogHeader from '@/components/release-plan/release-dialog-header.vue';
import ReleaseFilter from '@/components/release-plan/release-filter.vue';
import ReleaseLegend from '@/components/release-plan/release-legend.vue';
import ReleaseStep from '@/components/release-plan/release-step.vue';
import ReleaseStepItem from '@/components/release-plan/release-step-item.vue';
import ReleaseTitle from '@/components/release-plan/release-title.vue';
import Datepicker from '@/components/validation/datepicker.vue';
import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { SORT_DIRECTION } from '@/constants/common.const';
import { COOKIE_KEYS, DEFAULT_TIMEZONE } from '@/constants/cookie.const';
import { MONTH_FORMAT_WITH_DOT, YEAR_FORMAT } from '@/constants/date-time.const';
import { defaultInitialView, defaultPlugins } from '@/constants/fullcalendar.const';
import {
  PLAN_STATUS,
  PRODUCT_RELEASE_PLAN_PAGE_BUILD,
  PRODUCT_RELEASE_PLAN_PAGE_OPEN,
  PRODUCT_RELEASE_PLAN_TYPE,
  PRODUCT_STATUS_MAP,
  PRODUCT_STATUS_MAP_LANGUAGE_KEY,
  VERIFY_STATUS
} from '@/constants/products.const';
import {
  CALENDAR_RELEASE_PLAN_STATUS, EXTRA_FEATURES,
  EXTRA_FEATURES_KEY,
  listStatus,
  PLAY_OPTION,
  RELEASE_PLAN_FILTER_CALENDAR
} from '@/constants/release-plan.const';
import { PRODUCT_RELEASE_PLAN_DETAIL_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { LanguageCode } from '@/enums/language-code.enum';
import useProductStore from '@/stores/product.store';
import { useReleasePlanStore } from '@/stores/release-plan.store';
import { useUserStore } from '@/stores/user.store';
import type { CalendarEvent, PlanningCalendarEvent } from '@/types/calendar.type';
import type { ProductMetaResponse } from '@/types/product/product-response.type';
import type {
  ProductReleaseHistoryList,
  ProductReleaseHistoryListGrouped,
  ProductReleaseHistoryListSimple,
  ProductReleasePlanType
} from '@/types/release-plan/release-plan.type';
import type { ProductReleaseHistoryListRequest } from '@/types/release-plan/release-plan-request.type';
import { redirectTo } from '@/utils/common.util';
import { formatPrice } from '@/utils/currency.util';
import {
  getDateTimeByLocale,
  getDateWithOffset,
  getOrdinalSuffixByLocale,
  getTheDayOfTheWeekByLocale
} from '@/utils/date.util';
import { getAgeRatingText } from '@/utils/rating.util';
import { generateUUID } from '@/utils/uuid.util';

definePageMeta({
  middleware: ['handle-product-data-middleware']
});

const { locale, t } = useI18n();
const params = useRoute().params;
const productNo: number = parseInt(params.productId as string, 10);

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const productStore = useProductStore();
const { product } = storeToRefs(productStore);

const releasePlanStore = useReleasePlanStore();
const { storeReleasePlanInformation } = storeToRefs(releasePlanStore);

const productMeta = ref<ProductMetaResponse>();
const selectedTimezone = ref<string>(useCookieWithDomain(COOKIE_KEYS.TIMEZONE || '').value || DEFAULT_TIMEZONE);
const productReleaseHistoryListSimple = ref<ProductReleaseHistoryListSimple[]>([]);
const productReleaseHistoryList = ref<ProductReleaseHistoryList[]>([]);
const calendarData = ref<CalendarEvent[]>([]);
const releaseHistoryListGrouped = ref<ProductReleaseHistoryListGrouped[]>([]);
const calendarElm = ref();
const weekends = ref<boolean>(true);
const selectedDate = ref<Date>(new Date());
const isLoading = ref<boolean>(true);
const showCalendar = ref<boolean>(true);
const currentPage = ref(1);
const isLoadMore = ref(true);
const firstDay = computed(() => DateTime.fromJSDate(selectedDate.value).startOf('month').toMillis());
const lastDay = computed(() => DateTime.fromJSDate(selectedDate.value).endOf('month').toMillis());
const filterValue = ref<string[]>([
  RELEASE_PLAN_FILTER_CALENDAR.PRODUCT,
  RELEASE_PLAN_FILTER_CALENDAR.BUILD,
  RELEASE_PLAN_FILTER_CALENDAR.PRICE
]);
const uuid: string = `calendar_${generateUUID()}`;
const calendarOptions: CalendarOptions = {
  plugins: defaultPlugins,
  headerToolbar: false,
  initialView: defaultInitialView,
  selectMirror: true,
  navLinks: false, // can click day/week names to navigate views
  editable: false,
  selectable: false,
  firstDay: 0, // set Sunday is the first day of the week [0: sunday, 1: monday, ...]
  eventTimeFormat: { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' },
  height: 'auto',
  stickyHeaderDates: false,
  views: {
    dayGrid: {
      dayMaxEventRows: 9
    }
  },
  dayCellContent: function(arg: DayCellContentArg) {
    // Just show date number
    return { html: arg.date.getDate().toString() };
  },
  datesSet: function() {
    const rows = document.querySelectorAll<HTMLTableRowElement>('.fc-daygrid-body tr');
    rows.forEach((row: HTMLTableRowElement) => {
      const visibleDays = row.querySelectorAll<HTMLTableCellElement>('.fc-day:not(.fc-day-other)');
      if (visibleDays.length === 0) {
        row.style.display = 'none';
      } else {
        row.style.display = '';
      }
    });
  }
};
const isCurrentMonth = computed(() => {
  const today = new Date();
  return (
    today.getFullYear() === selectedDate.value.getFullYear() &&
      today.getMonth() === selectedDate.value.getMonth()
  );
});
const isViewModeCalendar = ref(true);

// Status release

const isReleaseLive = computed(() => {
  if (!storeReleasePlanInformation.value) {
    return false;
  }
  return (storeReleasePlanInformation.value.verifyStatus === VERIFY_STATUS.PASS && storeReleasePlanInformation.value.planStatus === PLAN_STATUS.PAGE_OPEN) ||
      (storeReleasePlanInformation.value.verifyStatus === VERIFY_STATUS.PASS && storeReleasePlanInformation.value.planStatus === PLAN_STATUS.PRE_PURCHASE) ||
      (storeReleasePlanInformation.value.verifyStatus === VERIFY_STATUS.PASS && storeReleasePlanInformation.value.planStatus === PLAN_STATUS.RELEASE);
});

const isShowBannerFirstTime = computed(() => {
  return storeReleasePlanInformation.value?.verifyStatus === VERIFY_STATUS.NONE && storeReleasePlanInformation.value?.planStatus === PLAN_STATUS.NONE && !calendarData.value?.length;
});

const releaseTime = computed(() => {
  return storeReleasePlanInformation.value?.plans?.release?.releasedAt;
});

// Handle full-calendar event

const colorMapping = (type: string) => {
  return listStatus.find((status: PlanningCalendarEvent) => status.value === type);
};

const onCurrentMonth = () => {
  selectedDate.value = new Date();
};

const onPrev = () => {
  selectedDate.value = DateTime.fromJSDate(selectedDate.value).minus({ months: 1 }).toJSDate();
};

const onNext = () => {
  selectedDate.value = DateTime.fromJSDate(selectedDate.value).plus({ months: 1 }).toJSDate();
};

const onMonthYearChange = () => {
  // Assuming fullCalendarRef is a ref to the FullCalendar component
  const calendarApi = calendarElm.value?.getApi();
  if (calendarApi) {
    calendarApi.gotoDate(selectedDate.value);
  }
};

const handleLoading = (loading: boolean) => {
  isLoading.value = loading;
};

const onSelectedViewModeCalendar = () => {
  isViewModeCalendar.value = true;
  setTimeout(() => {
    onMonthYearChange();
  });
};

const onSelectedViewModeList = () => {
  isViewModeCalendar.value = false;
};

/**
const getContentToolTip = (isProductPageOpen: boolean, rawData: ProductReleaseHistoryListSimple) => {
  let content = '';
  if (isProductPageOpen) {
    content = rawData?.data?.pageName ?? '';
  } else {
    const { data } = rawData;
    content = data?.pageName ? `<span>${data?.pageName}</span>` : '';
    if (data?.discountPrice && data?.discountRate && data?.price) {
      if (content.length) {
        content += '<br/>';
      }
      content += `<span>${data?.price}USD(${data?.discountRate}%) / ${data?.discountPrice}USD</span>`;
    }
    if (data?.buildName) {
      if (content.length) {
        content += '<br/>';
      }
      content += `<span>${data?.buildNo} / ${data?.buildName}(${t('studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_by_age', { rating: data?.ageRating })})</span>`;
    }
  }
  return content;
};
**/

const handleEvents = async (_: EventSourceFuncArg,
  successCallback: (events: EventInput[]) => void,
  failureCallback: (error: Error) => void
) => {
  try {
    successCallback(calendarData.value as EventInput[]);
  } catch (error) {
    failureCallback(error as Error);
  }
};

const getDateTimeCalendarList = (calendar: ProductReleaseHistoryList): string => {
  const LIST_HAD_DATE_RANGE_STATUS: ProductReleasePlanType[] = [
    PRODUCT_RELEASE_PLAN_TYPE.PRE_PURCHASE
  ];
  return LIST_HAD_DATE_RANGE_STATUS.includes(calendar.type)
    ? `${getDateTimeByLocale(calendar?.period?.startedAt)} ~ ${getDateTimeByLocale(calendar?.period?.endedAt || calendar?.period?.startedAt)}`
    : getDateTimeByLocale(calendar?.period?.startedAt);
};

const convertProductReleaseToCalendarEvent = (data: ProductReleaseHistoryListSimple[]): CalendarEvent[] => {
  return data.map((item: ProductReleaseHistoryListSimple) => {
    const titleRelease = PRODUCT_RELEASE_PLAN_PAGE_BUILD[item.type]
      ? `${t(`${PRODUCT_RELEASE_PLAN_PAGE_BUILD[item.type]}`).toString()}`
      : `${t('studio.prj_prod.this_prod.release_details_release_plan_release_timeline_official_release')}`;
    const newItem: CalendarEvent = {
      id: `${item.productNo}_${item.type}_${item.period.startedAt}`,
      title: PRODUCT_RELEASE_PLAN_PAGE_OPEN.includes(item.type) ? (item.data?.pageName ?? '') : titleRelease,
      start: getDateWithOffset(selectedTimezone.value, DateTime.fromJSDate(new Date(item.period.startedAt)).toFormat(YEAR_FORMAT)) || Date.now().toString(),
      end: item.period.endedAt ? getDateWithOffset(selectedTimezone.value, DateTime.fromJSDate(new Date(item.period.endedAt)).toFormat(YEAR_FORMAT)) || Date.now().toString() : null,
      createAt: item.period.startedAt,
      type: PRODUCT_STATUS_MAP[item.type],
      isProductPageOpen: PRODUCT_RELEASE_PLAN_PAGE_OPEN.includes(item.type),
      rawData: item
    };
    return newItem;
  });
};

const groupListByDate = (data: ProductReleaseHistoryList[]): ProductReleaseHistoryListGrouped[] => {
  const groupedObj = data.reduce((acc: { [date: string]: ProductReleaseHistoryList[] }, item: ProductReleaseHistoryList) => {
    item.txtStartedAt = getDateWithOffset(selectedTimezone.value, DateTime.fromJSDate(new Date(item.period.startedAt)).toFormat(YEAR_FORMAT)) || Date.now().toString();
    item.calendarType = PRODUCT_STATUS_MAP[item.type];
    const date = item.txtStartedAt.split('T')[0];

    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  return Object.keys(groupedObj).map((date: string) => ({
    date,
    items: groupedObj[date]
  }));
};

const getTypesFilters = () => {
  let filterParams: string[] | undefined;
  if (filterValue.value.length < 3) {
    filterParams = [];
    for (let i = 0; i < filterValue.value.length; i++) {
      const item = filterValue.value[i];
      if (item === RELEASE_PLAN_FILTER_CALENDAR.PRODUCT) {
        filterParams = [
          ...filterParams,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REQUEST,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_PASS,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_VERIFY_REJECT,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_LIVE,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_OPEN,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DISPLAY_OFF,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REQUEST,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_DELETE_REJECT,
          PRODUCT_RELEASE_PLAN_TYPE.PAGE_LIVE
        ];
      }
      if (item === RELEASE_PLAN_FILTER_CALENDAR.BUILD) {
        filterParams = [
          ...filterParams,
          PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REQUEST,
          PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY,
          PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_PASS,
          PRODUCT_RELEASE_PLAN_TYPE.RATING_BUILD_VERIFY_REJECT,
          PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_REQUEST,
          PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY,
          PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_PASS,
          PRODUCT_RELEASE_PLAN_TYPE.RELEASE_VERIFY_REJECT,
          PRODUCT_RELEASE_PLAN_TYPE.RELEASE_LIVE,
          PRODUCT_RELEASE_PLAN_TYPE.RELEASE,
          PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_RATING,
          PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_RUN,
          PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_PURCHASE,
          PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_DISPLAY,
          PRODUCT_RELEASE_PLAN_TYPE.RESTRICT_REPORT,
          PRODUCT_RELEASE_PLAN_TYPE.BUILD_LIVE
        ];
      }
      if (item === RELEASE_PLAN_FILTER_CALENDAR.PRICE) {
        filterParams = [
          ...filterParams,
          PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_REQUEST,
          PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY,
          PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_PASS,
          PRODUCT_RELEASE_PLAN_TYPE.PRICE_VERIFY_REJECT,
          PRODUCT_RELEASE_PLAN_TYPE.DISCOUNT_LIVE,
          PRODUCT_RELEASE_PLAN_TYPE.PRE_PURCHASE,
          PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_SALE_OFF
        ];
      }
    }
  }
  return filterParams;
};

// Handle API

const changeStatusToReadyToOpen = (data: ProductReleaseHistoryListSimple[] | ProductReleaseHistoryList[]) => {
  return data.map((item: ProductReleaseHistoryListSimple | ProductReleaseHistoryList) => {
    if (item.type === PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_PAGE_OPEN && item.period.startedAt > Date.now()) {
      item.type = PRODUCT_RELEASE_PLAN_TYPE.PRODUCT_READY_TO_OPEN;
    }
    return item;
  });
};

const fetchCalendarApi = async (params: ProductReleaseHistoryListRequest) => {
  const dataApi = await fetchProductReleaseHistoryListSimpleApi(params);
  const data = changeStatusToReadyToOpen(dataApi?.histories ?? []) as ProductReleaseHistoryListSimple[];
  productReleaseHistoryListSimple.value = [...productReleaseHistoryListSimple.value, ...data];
  if (data.length < 100) {
    isLoadMore.value = false;
  }
};

const fetchListApi = async (params: ProductReleaseHistoryListRequest) => {
  const dataApi = await fetchProductReleaseHistoryListApi(params);
  const data = changeStatusToReadyToOpen(dataApi?.contents ?? []) as ProductReleaseHistoryList[];
  productReleaseHistoryList.value = [...productReleaseHistoryList.value, ...data];
  if (data.length < 100) {
    isLoadMore.value = false;
  }
};

const getProductMeta = async () => {
  productMeta.value = await fetchProductsMetaApi({ gameId: product.value?.gameId, languageCd: selectedGroupInfo.value?.languageCd || 'ko' });
};

const callApiData = async () => {
  const paramsCalendar: ProductReleaseHistoryListRequest = {
    productNo,
    types: getTypesFilters(),
    start: formatToDateTime(selectedDate.value).minus({ month: 1 }).startOf('month').toMillis(),
    end: formatToDateTime(selectedDate.value).plus({ month: 1 }).endOf('month').toMillis(),
    direction: SORT_DIRECTION.DESC
  };
  const paramsList: ProductReleaseHistoryListRequest = {
    productNo,
    types: getTypesFilters(),
    page: currentPage.value,
    size: 100,
    start: formatToDateTime(selectedDate.value).startOf('month').toMillis(),
    end: formatToDateTime(selectedDate.value).endOf('month').toMillis(),
    direction: SORT_DIRECTION.DESC
  };
  const arr = currentPage.value === 1 ? [fetchCalendarApi(paramsCalendar), fetchListApi(paramsList)] : [fetchListApi(paramsList)];
  await Promise.all(arr);
  calendarData.value = convertProductReleaseToCalendarEvent(productReleaseHistoryListSimple.value);
  releaseHistoryListGrouped.value = groupListByDate(productReleaseHistoryList.value.filter((item: ProductReleaseHistoryList) => item.period.startedAt > firstDay.value && item.period.startedAt < lastDay.value));
};

const init = async () => {
  await Promise.all([
    getProductMeta(),
    releasePlanStore.getReleasePlanInformation(productNo)
  ]);
  await callApiData();
  await showPopupInformationRelease();
};

const loadMore = async () => {
  if (!isLoadMore.value) {
    return;
  }
  currentPage.value += 1;
  await callApiData();
  onMonthYearChange();
};

const resetData = () => {
  productReleaseHistoryListSimple.value = [];
  productReleaseHistoryList.value = [];
  calendarData.value = [];
  releaseHistoryListGrouped.value = [];
};

// Handle redirect function

const goToDetail = () => {
  redirectTo(PRODUCT_RELEASE_PLAN_DETAIL_PAGE_URL, { query: { fromCalendar: Confirmation.YES } });
};

const showPopupInformationRelease = async () => {
  const isFirstReleaseList: number[] = JSON.parse(localStorage.getItem('isFirstReleaseListIndex') ?? '[]') ?? [];
  const isExitsRelease: boolean = !!isFirstReleaseList?.find((item: number) => item === productNo);
  if (isReleaseLive.value && !isExitsRelease) {
    const srcImage = await fetchReleasePlanImageReleasedApi(productNo) ?? '';
    showDialog({
      component: shallowRef(ReleaseCompletionDialog),
      props: {
        srcImage,
        productNo
      }
    });
    localStorage.setItem('isFirstReleaseListIndex', JSON.stringify([...isFirstReleaseList, productNo]));
  }
};

await init();

watch(() => selectedDate.value, async () => {
  currentPage.value = 1;
  resetData();
  if (filterValue.value.length) {
    await callApiData();
  }
  onMonthYearChange();
});

watch(() => filterValue.value, async () => {
  const calendarApi = calendarElm.value?.getApi();
  currentPage.value = 1;
  resetData();
  if (filterValue.value.length) {
    await callApiData();
  }
  if (calendarApi) {
    calendarApi.refetchEvents();
  }
});
</script>
<style lang="css" src="@@/assets/css/calendar.css" />

<style scoped lang="scss">
.z-hidden {
  z-index: -1;
}

.fc .fc-daygrid-day.fc-day-other {
  visibility: hidden;
}

.fc-event .stds-tooltip {
  cursor: default !important;
}

:deep(.studio-calendar) {
  .fc-daygrid-more-link {
    display: none !important;
  }
}
</style>
