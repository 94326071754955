<!--ReleaseFilterComponent-->
<template>
  <s-contents-popup
    placement="bottom-end"
    trigger="click"
    :distance="4"
    :offset="[0, 0]"
    :minWidth="200"
    :theme="'studio-filter-list'"
    @shown="() => (isShowFilter = true)"
    @hidden="() => (isShowFilter = false)"
  >
    <template #target>
      <button type="button" class="flex items-center gap-12 h-32">
        <span class="text-md leading-sm text-on-surface-elevation-3">
          {{ getFilterName }}
        </span>
        <s-icon
          size="xl"
          icon="ic-v2-control-select-arrow-down-fill"
          class="text-on-surface-elevation-2"
          :class="{ 'rotate-180': isShowFilter }"
        />
      </button>
    </template>
    <template #contents>
      <s-contents-popup-content-body class="!my-16 !px-16">
        <s-checkbox
          size="sm"
          align="middle"
          class="flex [&>div>label]:leading-lg mb-8"
          name="releaseFilterCheckAll"
          :value="RELEASE_PLAN_FILTER_CALENDAR.ALL"
          :modelValue="isCheckAll"
          @update:modelValue="handleCheckAll()"
        >
          {{ LABEL_RELEASE_PLAN_FILTER_CALENDAR_ALL }}
        </s-checkbox>
        <checkbox-group v-model="filterValue" class="space-y-8" :options="filterOptions">
          <template #default="{ label, value }">
            <s-checkbox
              :id="value"
              :value="value"
              size="sm"
              align="middle"
              class="flex [&>div>label]:leading-lg"
            >
              {{ label }}
            </s-checkbox>
          </template>
        </checkbox-group>
      </s-contents-popup-content-body>
    </template>
  </s-contents-popup>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import { RELEASE_PLAN_FILTER_CALENDAR } from '@/constants/release-plan.const';
import type { FormOption } from '@/types/common/form.type';

const { t } = useI18n();

const props = defineProps<{
  modelValue: string[];
}>();

const emits = defineEmits<{
  'update:modelValue': [v: string[]];
}>();

const LABEL_RELEASE_PLAN_FILTER_CALENDAR_ALL = t(
  'studio.prj_prod.this_prod.release_stt_filter_cbox_all'
);

const filterValue = ref<string[]>(props.modelValue);
const isCheckAll = ref<boolean>(true);

const isShowFilter = ref(false);
const filterOptions: FormOption[] = [
  {
    value: RELEASE_PLAN_FILTER_CALENDAR.PRODUCT,
    label: t('studio.prj_prod.this_prod.release_stt_filter_cbox_prod_page')
  },
  {
    value: RELEASE_PLAN_FILTER_CALENDAR.BUILD,
    label: t('studio.prj_prod.this_prod.release_stt_filter_cbox_build')
  },
  {
    value: RELEASE_PLAN_FILTER_CALENDAR.PRICE,
    label: t('studio.prj_prod.this_prod.release_stt_filter_cbox_price_and_discounts')
  }
];

watch(
  () => props.modelValue,
  (v: string[]) => {
    filterValue.value = v;
  }
);

watch(
  () => filterValue.value,
  () => {
    isCheckAll.value = filterValue.value.length === 3;
    emits('update:modelValue', filterValue.value);
  }
);

const getFilterName = computed(() => {
  let str;
  if (!filterValue.value.length) {
    return t('studio.prj_prod.this_prod.release_stt_filter_none_check_guide');
  }
  if (filterValue.value.length === 3) {
    str = LABEL_RELEASE_PLAN_FILTER_CALENDAR_ALL;
  } else {
    str = filterOptions
      .filter((opt: FormOption) => filterValue.value.includes(opt.value))
      .map((opt: FormOption) => opt.label)
      .join(', ');
  }
  return str;
});

const handleCheckAll = () => {
  if (isCheckAll.value) {
    filterValue.value = [];
  } else {
    filterValue.value = [
      RELEASE_PLAN_FILTER_CALENDAR.PRODUCT,
      RELEASE_PLAN_FILTER_CALENDAR.BUILD,
      RELEASE_PLAN_FILTER_CALENDAR.PRICE
    ];
  }
};
</script>
