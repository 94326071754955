import { CALENDAR_RELEASE_PLAN_STATUS } from '@/constants/release-plan.const';

export const mapBackgroundColorByCalendarReleasePlanStatus = (type: string) => {
  switch (type) {
    case CALENDAR_RELEASE_PLAN_STATUS.REQUEST:
      return 'bg-[#CEDDFF]';
    case CALENDAR_RELEASE_PLAN_STATUS.VERIFY:
      return 'bg-[#FFC226]';
    case CALENDAR_RELEASE_PLAN_STATUS.COMPLETE:
      return 'bg-[#37A458]';
    case CALENDAR_RELEASE_PLAN_STATUS.REJECT:
      return 'bg-[#EC3B47]';
    case CALENDAR_RELEASE_PLAN_STATUS.LIVE:
      return 'bg-[#1355FF]';
    case CALENDAR_RELEASE_PLAN_STATUS.STOP:
      return 'bg-[#BBBFC3]';
    default:
      return '';
  }
};
