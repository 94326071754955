<template>
  <div class="flex items-center gap-24">
    <div
      class="relative shrink-0"
      :class="{
        'z-[2]': isFirst
      }"
    >
      <i
        class="absolute w-[.2rem] bottom-4 h-[20rem] left-[.5rem]"
        :class="{
          'bg-white before:absolute before:inset-y-0 before:left-0 before:border-l-[.2rem] before:border-dotted before:border-border':
            isFirst && inMultiItems,
          'bg-border': !isFirst
        }"
      ></i>
      <i
        class="relative block w-12 h-12 rounded-full z-[2]"
        :class="isPastTimeLine ? 'bg-on-surface-elevation-4' : 'bg-border'"
      ></i>
    </div>
    <div
      class="flex-1 rounded-3xl py-16 px-20 bg-neutral-variant-2-pressed flex items-center justify-between"
    >
      <div>
        <span
          v-if="dateTime && dateTime.length > 0"
          class="text-xs leading-xs font-regular text-on-surface-elevation-4"
        >
          {{ dateTime }}
        </span>
        <div class="flex items-center gap-8">
          <i
            v-if="!isBeforeRelease"
            class="w-12 h-12 rounded-full shrink-0"
            :class="mapBackgroundColorByCalendarReleasePlanStatus(type || '')"
          ></i>
          <span
            class="text-md font-medium leading-lg flex-1 break-all line-clamp-1"
            :class="isBeforeRelease ? 'text-placeholder' : 'text-on-surface-elevation-2'"
          >
            {{ stateMessage }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import { mapBackgroundColorByCalendarReleasePlanStatus } from '@/utils/release-plan.util';

const props = defineProps<{
  stateMessage: string;
  dateTime?: string;
  type: string;
  releaseTime?: number | null;
  isFirst?: boolean;
  isPastTimeLine?: boolean;
  inMultiItems?: boolean;
}>();

const isBeforeRelease = computed(() => {
  if (props.dateTime) {
    if (!props.releaseTime) {
      return false;
    }
    return false;
    // open when need check releaseTime
    // return formatUnknownSrcToMillis(new Date()) >= formatUnknownSrcToMillis(props.releaseTime);
  }
  return false;
});
</script>
